/**
 * Embla Carousel Component Guide
 * ==============================
 *
 * This is a custom web component for creating carousels using Embla Carousel.
 *
 * BASIC USAGE:
 * ------------
 * <carousel-component
 *   data-embla-container-id="my-unique-id"
 *   data-embla-options='{"align": "start", "loop": true}'
 * >
 *   <div class="overflow-hidden">
 *     <div id="my-unique-id">
 *       <ul class="flex">
 *         <li class="embla-slide">Slide 1</li>
 *         <li class="embla-slide">Slide 2</li>
 *       </ul>initializeEmblaApi
 *     </div>
 *   </div>
 * </carousel-component>
 *
 * NAVIGATION BUTTONS:
 * ------------------
 * Add these elements inside the carousel-component:
 * <button class="embla-button--prev">Previous</button>
 * <button class="embla-button--next">Next</button>
 *
 * DISABLED NAVIGATION:
 * -------------------
 * To show disabled buttons instead of hiding them:
 * <carousel-component data-embla-disable-navigation="true">
 *   <!-- carousel content -->
 * </carousel-component>
 *
 * DOTS NAVIGATION:
 * ---------------
 * Add this element inside the carousel-component:
 * <div class="embla-dots"></div>
 *
 * SLIDE COUNTER:
 * -------------
 * Add this element inside the carousel-component:
 * <div class="embla-counter">1/5</div>
 *
 * CONTROL LINKS:
 * -------------
 * To create external controls that trigger specific slides:
 * <li class="control-link" data-index="0" data-carousel="my-unique-id">Slide 1</li>
 * <li class="control-link" data-index="1" data-carousel="my-unique-id">Slide 2</li>
 *
 * HIGHLIGHT SLIDES IN VIEWPORT:
 * ---------------------------
 * To highlight slides that are in the viewport:
 * <carousel-component
 *   data-embla-highlight-options='{
 *     "active": true,
 *     "activeClass": "is-active",
 *     "targetSelector": ".target-element",
 *     "maxWidth": 768
 *   }'
 * >
 *   <!-- carousel content -->
 * </carousel-component>
 *
 * FADE EFFECT:
 * -----------
 * To enable fade effect between slides (requires embla-carousel-fade plugin):
 * <carousel-component
 *   data-embla-fade-options='{
 *     "enabled": true,
 *     "startFrom": 1024
 *   }'>
 *   <!-- carousel content -->
 * </carousel-component>
 *
 * The fade effect creates smooth transitions between slides.
 * - enabled: Set to true to activate the fade effect
 * - startFrom: The minimum viewport width (in pixels) where fade should be active
 *   Set to 0 to enable fade at all screen sizes
 *
 * SEGMENT TRACKING:
 * ---------------
 * To enable segment tracking:
 * <carousel-component data-embla-enable-segment-track="true">
 *   <!-- carousel content with .embla__slide-link elements -->
 * </carousel-component>
 */

import EmblaCarousel, { EmblaCarouselType } from "embla-carousel";
import Fade from "embla-carousel-fade";
import useAnalytics from "@composables/useAnalytics";

interface HighlightSlidesOptions {
  active: boolean;
  activeClass: string;
  targetSelector: string;
  maxWidth: number;
}

interface FadeOptions {
  enabled: boolean;
  startFrom: number;
}

class DefaultCarousel extends HTMLElement {
  emblaOptions: Record<string, any> | null = null;
  isSegmentTrack: boolean = false;
  emblaApi: EmblaCarouselType | null = null;
  slideLinksElement: NodeListOf<HTMLElement> | null = null;
  nextBtn: HTMLElement | null = null;
  prevBtn: HTMLElement | null = null;
  dotsNode: HTMLElement | null = null;
  dotNodes: HTMLElement[] = [];
  emblaContainer: HTMLElement | null = null;
  highlightSlidesOptions: HighlightSlidesOptions | null = null;
  fadeOptions: FadeOptions | null = null;
  disableNavigation: boolean = false;
  slideCounter: HTMLElement | null = null;
  resizeObserver: ResizeObserver | null = null;
  plugins: any[] = [];
  constructor() {
    super();
    this.setupElements();
    this.mount();
  }

  setupElements() {
    const { containerId, emblaOptions, highlightOptions, fadeOptions } =
      this.parseEmblaData();
    if (!containerId || !this.querySelector(`#${containerId}`)) {
      console.error(
        "No container id found. Please add data-embla-container-id attribute to the carousel element."
      );
      return;
    }

    this.emblaOptions = emblaOptions;
    this.prevBtn = this.querySelector(".embla-button--prev");
    this.nextBtn = this.querySelector(".embla-button--next");
    this.dotsNode = this.querySelector(".embla-dots");
    this.slideCounter = this.querySelector(".embla-counter");
    this.emblaContainer = this.querySelector(`#${containerId}`);
    this.isSegmentTrack = this.dataset.emblaEnableSegmentTrack === "true";
    this.disableNavigation = this.dataset.emblaDisableNavigation === "true";
    this.fadeOptions = fadeOptions;
    this.slideLinksElement =
      this.querySelectorAll<HTMLElement>(".embla__slide-link");
    this.highlightSlidesOptions = highlightOptions as HighlightSlidesOptions;
    this.plugins = [];
  }

  segmentTrack(action: string, name: string): void {
    const { segmentTrack } = useAnalytics();
    segmentTrack("Product List Filtered", {
      filter_action: action,
      filter_name: name,
    });
  }

  parseEmblaData(): {
    containerId: string | null;
    emblaOptions: Record<string, any> | null;
    highlightOptions: Record<string, any> | null;
    fadeOptions: FadeOptions | null;
  } {
    const containerId = this.dataset.emblaContainerId;
    const emblaOptionsString = this.dataset.emblaOptions;
    const highlightOptionsString = this.dataset.emblaHighlightOptions;
    const fadeOptionsString = this.dataset.emblaFadeOptions;

    const result: {
      containerId: string | null;
      emblaOptions: Record<string, any> | null;
      highlightOptions: Record<string, any> | null;
      fadeOptions: FadeOptions | null;
    } = {
      containerId: containerId || null,
      emblaOptions: null,
      highlightOptions: null,
      fadeOptions: null,
    };

    if (emblaOptionsString) {
      try {
        result.emblaOptions = JSON.parse(emblaOptionsString);
      } catch (error) {
        console.error("Error parsing data-embla-options attribute:", error);
      }
    }

    if (highlightOptionsString) {
      try {
        result.highlightOptions = JSON.parse(highlightOptionsString);
      } catch (error) {
        console.error(
          "Error parsing data-embla-highlight-options attribute:",
          error
        );
      }
    }

    if (fadeOptionsString) {
      try {
        const parsedOptions = JSON.parse(fadeOptionsString);
        result.fadeOptions = {
          enabled: Boolean(parsedOptions.enabled),
          startFrom: Number(parsedOptions.startFrom || 0),
        };
      } catch (error) {
        console.error(
          "Error parsing data-embla-fade-options attribute:",
          error
        );
      }
    }

    return result;
  }

  mount() {
    this.initializeEmblaApi();
    this.initializeControlsAndHandlers();
  }

  initializeControlsAndHandlers() {
    if (this.highlightSlidesOptions) {
      this.highlightSlidesInViewportHandler();
    }

    if (this.nextBtn && this.prevBtn) {
      const removePrevNextBtnsClickHandlers =
        this.addPrevNextBtnsClickHandlers();
      this.emblaApi?.on("destroy", removePrevNextBtnsClickHandlers);
    }

    if (this.dotsNode) {
      const removeDotBtnsAndClickHandlers = this.addDotBtnsAndClickHandlers();
      this.emblaApi?.on("destroy", removeDotBtnsAndClickHandlers);
    }

    if (this.slideCounter) {
      const removeSlideCounterHandler = this.addSlideCounterHandler();
      this.emblaApi?.on("destroy", removeSlideCounterHandler);
    }

    if (this.isSegmentTrack && this.slideLinksElement?.length) {
      this.slideLinksElement.forEach((slideLinkElement) => {
        slideLinkElement.addEventListener("click", () => {
          const { segmentTrack } = this;
          if (segmentTrack) {
            segmentTrack("added", slideLinkElement.textContent?.trim() || "");
          }
        });
      });
    }
  }
  setupResizeObserver() {
    // Clean up any existing observer
    this.cleanupResizeObserver();

    // Only set up observer if we have fade options with a startFrom threshold
    if (this.isFadeEnabled() && this.fadeOptions?.startFrom && this.emblaApi) {
      this.resizeObserver = new ResizeObserver(this.handleResize.bind(this));
      this.resizeObserver.observe(document.documentElement);

      // Clean up on component destruction
      this.emblaApi.on("destroy", () => this.cleanupResizeObserver());
    }
  }

  cleanupResizeObserver() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  handleResize() {
    if (!this.isFadeEnabled() || !this.emblaApi || !this.fadeOptions?.startFrom)
      return;

    const currentWidth = window.innerWidth;
    const threshold = this.fadeOptions.startFrom;
    const shouldHaveFade = currentWidth >= threshold;
    const hasFade = this.isFadePluginEnabled();

    // Only reinitialize when crossing the threshold (to avoid an infinite loop)
    if (shouldHaveFade !== hasFade) {
      this.reinitializeCarousel(shouldHaveFade);
    }
  }

  isFadeEnabled(): boolean {
    return Boolean(this.fadeOptions?.enabled);
  }

  isFadePluginEnabled(): boolean {
    return this.plugins.some((plugin) => plugin?.name === "fade");
  }

  shouldUseFade(): boolean {
    if (!this.fadeOptions?.enabled) return false;

    if (this.fadeOptions.startFrom) {
      return window.innerWidth >= this.fadeOptions.startFrom;
    }

    return true; // If enabled but no startFrom, always use fade
  }

  reinitializeCarousel(withFade: boolean) {
    // Skip if we don't have an API instance
    if (!this.emblaApi) return;

    // Store current state
    const currentIndex = this.emblaApi.selectedScrollSnap();

    // Clean up observers before destroying
    this.cleanupResizeObserver();

    // Destroy current instance
    this.emblaApi.destroy();

    // Reset plugins array and add fade if needed
    this.plugins = [];
    if (withFade) {
      this.plugins.push(Fade());
    }

    // Reinitialize with the correct plugins already set
    // Use a different initialization to avoid redundant plugin setup
    if (this.emblaContainer) {
      // Initialize with plugins (already set above)
      this.emblaApi = this.emblaOptions
        ? EmblaCarousel(this.emblaContainer, this.emblaOptions, this.plugins)
        : EmblaCarousel(this.emblaContainer, {}, this.plugins);

      // Set up handlers without reinitializing plugins
      this.initializeControlsAndHandlers();

      // Set up observer again after reinitializing
      if (this.isFadeEnabled() && this.fadeOptions?.startFrom) {
        this.setupResizeObserver();
      }

      // Restore state
      if (this.emblaApi && currentIndex !== undefined) {
        this.emblaApi.scrollTo(currentIndex, false);
      }
    }
  }

  initializeEmblaApi() {
    if (this.emblaContainer) {
      // Clear existing plugins
      this.plugins = [];

      // Set up fade plugin if it should be used
      if (this.shouldUseFade()) {
        this.plugins.push(Fade());
      }

      // Initialize with plugins
      this.emblaApi = this.emblaOptions
        ? EmblaCarousel(this.emblaContainer, this.emblaOptions, this.plugins)
        : EmblaCarousel(this.emblaContainer, {}, this.plugins);

      // Ensure all event handlers are properly set up
      if (this.emblaApi) {
        // Only set up resize observer when we have a startFrom threshold
        if (this.isFadeEnabled() && this.fadeOptions?.startFrom) {
          this.setupResizeObserver();
        }

        // Set up control links if they exist for this carousel
        const carouselId = this.dataset.emblaContainerId;
        if (
          carouselId &&
          document.querySelector(`.control-link[data-carousel="${carouselId}"]`)
        ) {
          this.setupControlLinks();
        }
      }
    }
  }

  addTogglePrevNextBtnsActive() {
    const togglePrevNextBtnsState = () => {
      if (this.emblaApi?.canScrollPrev()) {
        if (this.disableNavigation) {
          this.prevBtn?.classList.remove("opacity-50");
          this.prevBtn?.removeAttribute("disabled");
        } else {
          this.prevBtn?.classList.remove("invisible");
        }
      } else {
        if (this.disableNavigation) {
          this.prevBtn?.classList.add("opacity-50");
          this.prevBtn?.setAttribute("disabled", "");
        } else {
          this.prevBtn?.classList.add("invisible");
        }
      }

      if (this.emblaApi?.canScrollNext()) {
        if (this.disableNavigation) {
          this.nextBtn?.classList.remove("opacity-50");
          this.nextBtn?.removeAttribute("disabled");
        } else {
          this.nextBtn?.classList.remove("invisible");
        }
      } else {
        if (this.disableNavigation) {
          this.nextBtn?.classList.add("opacity-50");
          this.nextBtn?.setAttribute("disabled", "");
        } else {
          this.nextBtn?.classList.add("invisible");
        }
      }
    };

    this.emblaApi?.on("select", togglePrevNextBtnsState);
    this.emblaApi?.on("init", togglePrevNextBtnsState);
    this.emblaApi?.on("reInit", togglePrevNextBtnsState);

    return () => {
      if (this.disableNavigation) {
        this.prevBtn?.classList.remove("opacity-50");
        this.nextBtn?.classList.remove("opacity-50");
        this.prevBtn?.removeAttribute("disabled");
        this.nextBtn?.removeAttribute("disabled");
      } else {
        this.prevBtn?.classList.remove("invisible");
        this.nextBtn?.classList.remove("invisible");
      }
    };
  }

  addPrevNextBtnsClickHandlers() {
    const scrollPrev = () => this.emblaApi?.scrollPrev();
    const scrollNext = () => this.emblaApi?.scrollNext();

    this.prevBtn?.addEventListener("click", scrollPrev, false);
    this.nextBtn?.addEventListener("click", scrollNext, false);

    const removeTogglePrevNextBtnsActive = this.addTogglePrevNextBtnsActive();

    return () => {
      removeTogglePrevNextBtnsActive();
      this.prevBtn?.removeEventListener("click", scrollPrev, false);
      this.nextBtn?.removeEventListener("click", scrollNext, false);
    };
  }

  addDotBtnsAndClickHandlers() {
    const addDotBtnsWithClickHandlers = () => {
      if (!this.dotsNode || !this.emblaApi) return;

      try {
        this.dotsNode.innerHTML = this.emblaApi
          .scrollSnapList()
          .map(
            () =>
              `<button class="embla-dot w-2 h-2 rounded-full bg-neutral-300 m-0.5" type="button" aria-label="Scroll to">
              </button>`
          )
          .join("");

        this.dotNodes = Array.from(
          this.dotsNode.querySelectorAll(".embla-dot")
        );
        this.dotNodes.forEach((dotNode, index) => {
          dotNode.addEventListener(
            "click",
            () => this.emblaApi?.scrollTo(index),
            false
          );
        });
      } catch (error) {
        console.error("Error setting up dot navigation:", error);
      }
    };

    const toggleDotBtnsActive = () => {
      const previous = this.emblaApi?.previousScrollSnap();
      const selected = this.emblaApi?.selectedScrollSnap();

      // Add safety checks
      if (
        previous !== undefined &&
        selected !== undefined &&
        this.dotNodes.length > previous &&
        this.dotNodes.length > selected
      ) {
        this.dotNodes[previous].classList.remove("bg-neutral-900");
        this.dotNodes[selected].classList.add("bg-neutral-900");
      }
    };

    // Register events separately
    if (this.emblaApi) {
      this.emblaApi.on("init", addDotBtnsWithClickHandlers);
      this.emblaApi.on("reInit", addDotBtnsWithClickHandlers);
      this.emblaApi.on("init", toggleDotBtnsActive);
      this.emblaApi.on("reInit", toggleDotBtnsActive);
      this.emblaApi.on("select", toggleDotBtnsActive);
    }

    return () => {
      if (this.dotsNode) {
        this.dotsNode.innerHTML = "";
      }
    };
  }

  highlightSlidesInViewportHandler() {
    const { highlightSlidesOptions } = this;

    if (!highlightSlidesOptions?.active) return;

    const { activeClass, targetSelector, maxWidth } = highlightSlidesOptions;

    if (!activeClass || !targetSelector || !maxWidth) {
      console.warn(
        "Please provide activeClass, targetSelector, and maxWidth in highlightSlidesOptions"
      );
      return;
    }

    const highlightHandler = () => {
      const slides = this.emblaApi?.slideNodes();
      const slidesInView = this.emblaApi?.slidesInView();

      if (!slides || !slidesInView) return;

      slides.forEach((slide, index) => {
        const isSlideInView = slidesInView.includes(index);
        const targetNode = slide.querySelector(targetSelector);

        if (targetNode) {
          targetNode.classList.toggle(activeClass, isSlideInView);
        }
      });
    };

    const removeHighlightHandler = () => {
      this.emblaApi?.off("slidesInView", highlightHandler);
      const slides = this.emblaApi?.slideNodes();

      slides?.forEach((slide) => {
        const targetNode = slide.querySelector(targetSelector);
        if (targetNode) {
          targetNode.classList.remove(activeClass);
        }
      });
    };

    const addHighlightHandler = () =>
      this.emblaApi?.on("slidesInView", highlightHandler);

    const targetSize = () => window.innerWidth <= maxWidth;

    if (targetSize()) {
      addHighlightHandler();
    }

    this.emblaApi?.on("resize", () => {
      if (targetSize()) {
        addHighlightHandler();
      } else {
        removeHighlightHandler();
      }
    });
  }

  addSlideCounterHandler() {
    const updateSlideCounter = () => {
      if (!this.slideCounter || !this.emblaApi) return;

      let currentSlide = this.emblaApi.selectedScrollSnap() + 1; // Add 1 for human-readable index
      const totalSlides = this.emblaApi.slideNodes().length;

      // Check if we're at or near the end
      if (!this.emblaApi.canScrollNext()) {
        currentSlide = totalSlides; // Force to show the last slide number
      }

      this.slideCounter.textContent = `${currentSlide}/${totalSlides}`;
    };

    this.emblaApi?.on("select", updateSlideCounter);
    this.emblaApi?.on("init", updateSlideCounter);
    this.emblaApi?.on("reInit", updateSlideCounter);
    this.emblaApi?.on("settle", updateSlideCounter); // Add settle event for more accuracy

    return () => {
      this.emblaApi?.off("select", updateSlideCounter);
      this.emblaApi?.off("settle", updateSlideCounter);
    };
  }

  setupControlLinks() {
    // Get carousel ID from the container
    const carouselId = this.dataset.emblaContainerId;
    if (!carouselId || !this.emblaApi) return;

    // Only select control links that belong to this specific carousel
    const controlLinks = document.querySelectorAll(
      `.control-link[data-carousel="${carouselId}"]`
    );

    if (controlLinks.length === 0) return;

    const updateActiveControl = () => {
      const selectedIndex = this.emblaApi?.selectedScrollSnap() || 0;

      controlLinks.forEach((link, index) => {
        const dot = link.querySelector("span");
        if (index === selectedIndex) {
          dot?.classList.remove("opacity-20");
          dot?.classList.add("opacity-100");
          link.classList.add("font-semibold");
        } else {
          dot?.classList.add("opacity-20");
          dot?.classList.remove("opacity-100");
          link.classList.remove("font-semibold");
        }
      });
    };

    controlLinks.forEach((link) => {
      link.addEventListener("click", () => {
        const index = parseInt(link.getAttribute("data-index") || "0", 10);
        this.emblaApi?.scrollTo(index);
      });
    });

    this.emblaApi?.on("select", updateActiveControl);
    this.emblaApi?.on("init", updateActiveControl);
    updateActiveControl();
  }
}

if (!customElements.get("carousel-component")) {
  customElements.define("carousel-component", DefaultCarousel);
}
